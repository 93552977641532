//import dynamic from "next/dynamic";
//const SeoCheckComponent = dynamic(() => import("../seo-check-component"));
import Image from "next/image";
import { Jost } from "@lib/JostFont";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:786px)");

  return (
    <section
      className={`bg-black relative  pt-40 pl-[3rem] ${Jost.className} `}
    >
      <div className="absolute -top-[6rem] right-[4vw] sm:right-[25vw] z-[40]">
       {/*} <SeoCheckComponent /> */}
      </div>
      <Box>
        <Container maxWidth="xl" sx={{ py: 6 }}>
          <Grid container spacing={4}>
            {/* Row 1 */}
            <Grid item xs={12} md={3} sx={{ marginRight: "30px" }}>
            <Image
                    height={300}
                    width={200}
                    className="h-[4rem] w-auto -ml-[2rem] my-4"
                    alt="facebook icon"
                    src="/techcelerant-logo-footer.svg"
                  />
                  <p className="pb-5"></p>

              {/* address start */}
              <div>
                <div className="relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "10px",
                      paddingTop: "0.9rem",
                      height: "2px",
                      display: "inline-block",
                      borderLeft: "10px solid #753C62",
                      marginLeft: "-25px",
                    }}
                  />
                  <Typography
                    variant="p"
                    gutterBottom
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      paddingTop: "3px",
                      paddingLeft: "2px",
                    }}
                  >
                    Address
                  </Typography>
                </div>
              </div>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontFamily: "'Poppins', sans-serif",

                  fontSize: "20px",
                }}
              >
                <a
                  href="https://maps.google.com/?q=40.8152714,-74.009693
"
                >
                  <span
                    style={{
                      display: "flex",
                      position: "relative",
                      paddingBottom: "14px",
                    }}
                    className="footer-location-icon hover:underline"
                  >
                    1 Broad Ave, Unit # 4 Fairview NJ 07022, USA
                  </span>

                  <Image
                    height={300}
                    width={400}
                    layout="responsive"
                    className="pb-5"
                    alt="facebook icon"
                    src="/footer-map.webp"
                  />

                </a>
              </Typography>

              {/* ends */}
              <div className="flex space-x-2 max-w-[250px]">
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://facebook.com/techcelerant"
                >
                  <Image
                    height={200}
                    width={200}
                    className="pt-2"
                    alt="facebook icon"
                    src="/assets/social-icons/facebook.svg"
                  />
                </Link>
                <Link target="_blank" href="https://instagram.com/techcelerant">
                  <Image
                    height={200}
                    width={200}
                    className="pt-2"
                    alt="instagram icon"
                    src="/assets/social-icons/instagram.svg"
                  />
                </Link>

                <Link
                  target="_blank"
                  href="https://linkedin.com/company/techcelerant"
                >
                  <Image
                    height={200}
                    width={200}
                    className="pt-2"
                    alt="linkedin icon"
                    src="/assets/social-icons/linkedin.svg"
                  />
                </Link>

                <Link target="_blank" href="https://twitter.com/techcelerant">
                  <Image
                    height={160}
                    width={160}
                    className="pt-4"
                    alt="twitter icon"
                    src="/x-footer-logo.svg"
                  />
                </Link>
              </div>
            </Grid>
            {/* Row 2 */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{ marginLeft: isMobile ? "0px" : "30px" }}
            >
              <div style={{ height: "40px" }}>
                <div className="relative">
                  <span
                    style={{
                      paddingTop: "10px",
                      position: "absolute",
                      top: "10px",
                      height: "2px",
                      display: "inline-block",
                      borderLeft: "10px solid #753C62",
                      marginLeft: "-25px",
                    }}
                  />
                  <Typography
                    variant="p"
                    gutterBottom
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      paddingTop: "3px",
                      paddingLeft: "2px",
                    }}
                  >
                    Enquires
                  </Typography>
                </div>
              </div>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontFamily: "'Poppins', sans-serif",

                  fontSize: "20px",
                  overflowX: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.2rem",
                  paddingBottom: "20px",
                }}
              >
                <Link
                  href="mailto:info@techcelerant.com"
                  color="inherit"
                  underline="hover"
                >
                  info@techcelerant.com
                </Link>

              </Typography>

              <div
                style={{
                  height: "40px",
                  display: "inline-block",
                  paddingTop: "1rem",
                  paddingLeft: isMobile ? "0px" : "20px",
                }}
              >
                <div className="relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "15px",
                      paddingTop: "0.9rem",
                      height: "2px",
                      display: "inline-block",
                      borderLeft: "10px solid #753C62",
                      marginLeft: "-25px",
                    }}
                  />
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      paddingTop: "10px",
                      paddingLeft: "2px",
                    }}
                  >
                    Phone
                  </Typography>
                </div>
              </div>
              <Typography
                variant="h3"
                sx={{
                  color: "white",
                  fontFamily: "'Poppins', sans-serif",

                  fontSize: "20px",
                }}
              >
                <span className=" flex  flex-col space-y-[0.5rem] mt-2 font-mediumbold text-gray-400 text-md tracking-wider ">
                  <a
                    href="tel:+18324761293"
                    className="footer-logo-icon-phone hover:underline"
                  >
                    (+1) 832 476 1293
                  </a>{" "}
                  <a
                    href={`https://wa.me/18326153920`}
                    className="footer-logo-icon hover:underline"
                  >
                    {/* <img  src='/wp-footer-icon.png' />   */}
                    <p> (+1) 832 615 3920 </p>
                  </a>
                  <br />
                </span>
              </Typography>
            </Grid>
            {/* Row 3 */}
            <Grid item xs={12} md={4}>
              <div style={{ height: "40px", display: "inline-block" }}>
                <div className="relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "10px",
                      paddingTop: "0.9rem",
                      height: "2px",
                      display: "inline-block",
                      borderLeft: "10px solid #753C62",
                      marginLeft: "-25px",
                    }}
                  />
                  <Typography
                    variant="p"
                    gutterBottom
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      paddingTop: "3px",
                      paddingLeft: "2px",
                    }}
                  >
                    Digital Services
                  </Typography>
                </div>
              </div>
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "20px",
                  overflowX: "hidden",
                }}
              >
                <Link
                  href="/services/web-development"
                  color="inherit"
                  underline="hover"
                >
                  • Web Design & Development
                </Link>
                <br />
                <Link
                  href="/services/mobile-app-development"
                  color="inherit"
                  underline="hover"
                >
                  • Mobile App Development
                </Link>
                <br />

                <Link
                  href="/services/brand-development-services"
                  color="inherit"
                  underline="hover"
                >
                  • Brand Development & Strategy
                </Link>
                <br />
                <Link
                  href="/services/social-media-management"
                  color="inherit"
                  underline="hover"
                >
                  • Social Media Management
                </Link>

                <br />

                <Link
                  href="/services/graphic-designing"
                  color="inherit"
                  underline="hover"
                >
                  • Graphic Designing
                </Link>

                <br />

                <Link
                  href="/services/video-editing-and-animation"
                  color="inherit"
                  underline="hover"
                >
                  • Video Editing & Animation
                </Link>
                <br />

                <Link
                  href="/services/Corporate-Website-Development"
                  color="inherit"
                  underline="hover"
                >
                  • Corporate Website Development
                </Link>
                <br />

                <Link
                  href="/services/Business-Website-Development"
                  color="inherit"
                  underline="hover"
                >
                  • Business Website Development
                </Link>
              </Typography>
            </Grid>
            {/* Row 4 */}
            <Grid
              item
              xs={12}
              md={2}
              sx={{ marginLeft: isMobile ? "0px" : "-70px" }}
            >
              <div style={{ height: "40px", display: "inline-block" }}>
                <div className="relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "10px",
                      paddingTop: "0.9rem",
                      height: "2px",
                      display: "inline-block",
                      borderLeft: "10px solid #753C62",
                      marginLeft: "-25px",
                    }}
                  />
                  <Typography
                    variant="p"
                    gutterBottom
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      paddingTop: "1px",
                      paddingLeft: "2px",
                    }}
                  >
                    Useful Links
                  </Typography>
                </div>
              </div>
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  fontFamily: "'Poppins', sans-serif",

                  fontSize: "20px",
                  overflowX: "hidden",
                }}
              >
                <Link href="/" color="inherit" underline="hover">
                  • Home
                </Link>
                <br />
                <Link href="/about" color="inherit" underline="hover">
                  • About Us
                </Link>
                <br />
                <Link href="/contact" color="inherit" underline="hover">
                  • Contact Us
                </Link>

                <br />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <style jsx>
        {`
          .footer-logo-icon {
            display: flex;
            gap: 0.5rem;
            color: white;
            align-items: center;
          }
          .footer-logo-icon::before {
            content: url("/wp-logo-green.svg");
            aspect-ratio: 3/3;
            object-fit: contain;
            margin-right: -5px;
            display: inline-block;
            /* Added to ensure proper layout */
          }
          .footer-logo-icon-phone {
            display: flex;
            gap: 0.5rem;
            color: white;
            align-items: center;
          }
          .footer-logo-icon-phone::before {
            content: url("/footer-phone-logo-white.png");
            aspect-ratio: 3/3;
            object-fit: contain;
            display: inline-block;
            /* Added to ensure proper layout */
          }
          .footer-location-icon::before {
            content: url("/location-red-footer.png");
            aspect-ratio: 3/3;
            object-fit: contain;
            display: inline-block;
            /* Added to ensure proper layout */
            padding-right: 5px;
          }
        `}
      </style>
    </section>
  );
};

export default Footer;
